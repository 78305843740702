import React from "react";
import "./App.css";
import { Layout } from "./shared/Layout";
import { Header } from "./shared/Header";
import { TodoList } from "./shared/TodoList";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Statistics } from "./shared/Statistics";
import { Provider } from "react-redux"
import { store } from "./store/reducer";
import { ThemeStyles } from "./shared/ThemeStyle";

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <ThemeStyles />
        <Header />
        <Layout>
          <Routes>
            <Route path="/" element={<TodoList />} />
            <Route path="/statistics" element={<Statistics />} />
          </Routes>
        </Layout>
      </HashRouter>
    </Provider>
  );
}

export default App;
